import React, { useRef } from "react";

import Footer from "src/components/footer";
import GetInTouch from "src/components/get-in-touch";
import Header from "src/components/header";
import HelmetWrapper from "src/components/helmet-wrapper";
import { breadCrumbSchema } from "src/components/html-schema-snippets";
import Layout from "src/components/layout";
import CaseStudyPanel from "src/components/startups-landing-page/CaseStudyPanel";
import { PageTopInvestment } from "src/components/investment-landing-page/PageTopInvestment";
import Image from "src/assets/images/careers/quote-1.jpg";
import styled, { css } from "src/theme/styled";
import { GravitywellTheme } from "src/config/theme";
import {
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";
import Link from "src/components/link";
import {
  MediumColumnWidthWrapper,
  SmallColumnWidthWrapper
} from "src/components/confines-wrappers";
import { CtaButton } from "src/components/cta";

const StartupsPage = (): JSX.Element => {
  const contactRef = useRef<HTMLDivElement | null>(null);
  const handleCTAClick = () => scrollSmoothly(contactRef);
  const canonicalPath = "/investment";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Investment",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout className="layout">
      <HelmetWrapper
        title="Investment"
        description="Sometimes, when we're passionate about a project, we're able to provide our clients with seed investment in the form of 'Sweat Equity'."
        canonicalPath={canonicalPath}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaJson)}
        </script>
      </HelmetWrapper>

      <header role="banner">
        <Header
          initialLogo
          initialTheme="dark"
        />

        <PageTopInvestment />

      </header>

      <main>

        <Panel
          id="looking-for-investment"
          className="looking-for-investment content-container"
          background={GravitywellTheme.color.altLightGrey}
        >
          <MediumColumnWidthWrapper className="looking-for-investment-wrapper">
            <div className="copy">
              <h4 role="heading">
                Looking for investment?
              </h4>

              <p>
                Sometimes, when we're passionate about a project, we're able to provide our clients with seed investment in the form of 'Sweat Equity'.
              </p>

              <p>
                See below for some examples of startups we've helped in the past.
              </p>

              <div className="section-cta-container">
                <p className="book">
                  <span className="off-screen">
                    Click here and
                    {" "}
                  </span>

                  <Link
                    type="styled"
                    color="brandColor"
                    href="##"
                    onClick={handleCTAClick}
                  >
                    Find out more
                  </Link>
                </p>
              </div>

            </div>

            <div className="image">
              <img
                className="full"
                src={Image}
                alt="Gravitywell having a internal meeting about an upcoming project"
              />
            </div>
          </MediumColumnWidthWrapper>
        </Panel>

        <CaseStudyPanel
          label="Mezze"
          heading="A new Software as a Service platform for the Food industry."
          linkCta="View Project"
          linkUrl="/projects/mezze"
          backgroundImage="https://www.gravitywell.co.uk/static/07d6e7ea315627fd7f041d7da2685a16/6ca10/Desktop%20hero%402x.jpg"
          desktopLayout="left"
          keyPoints={[
            "Entirely funded by Gravitywell",
            "Launched in November 2020",
            "Currently processing over £2m per month on behalf of its clients"
          ]}
        />

        <CaseStudyPanel
          label="Found"
          heading="Found is an AI based app which reunites owners with lost valuables."
          linkCta="View Project"
          linkUrl="/projects/found"
          backgroundImage="https://www.gravitywell.co.uk/static/33ed546a86b47becf7b67990a881e0e0/48b74/FoundApp_valuables_1460.jpg"
          desktopLayout="right"
          keyPoints={[ "Multiple rounds of investment", "Consistent, continous growth" ]}
        />

        <CaseStudyPanel
          label="Jury's Out"
          heading="An ambitious Social Media startup with a novel and compelling hook."
          backgroundImage="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
          desktopLayout="left"
          keyPoints={[ "Currently in seed investment round" ]}
          linkCta="Case Study coming soon"
        />
      </main>

      <Panel
        id="wrap-up"
        className="looking-for-investment content-container"
        background={GravitywellTheme.color.lightestGrey}
      >
        <MediumColumnWidthWrapper className="looking-for-investment-wrapper">

          <p className="center-copy">
            If your project needs an investment we may be able to help you find seed capital. We may also be able to help by investing our time to accelerate your project with an initial MVP phase. If you would like to talk to us about this opportunity then get in touch to arrange a discussion - we'd love to hear your pitch!
          </p>

        </MediumColumnWidthWrapper>
      </Panel>

      <Panel
        className="looking-for-investment content-container"
        background={GravitywellTheme.color.brandColorLight}
      >
        <SmallColumnWidthWrapper style={{ textAlign: "center" }}>
          <h3
            className="center-copy"
            style={{
              marginBottom: "3rem",
              color: GravitywellTheme.color.white
            }}
          >
            Our new incubator program offers hands-on time to build your product, alongside hours of tailored consultancy and start-up support.
          </h3>

          <CtaButton
            type="primary"
            text="Take a look"
            href="/incubator"
          />
        </SmallColumnWidthWrapper>
      </Panel>

      <footer
        role="contentinfo"
        ref={contactRef}
      >
        <GetInTouch
          title="Let's get started"
          slogan="Got a great idea?"
        />

        <Footer />
      </footer>
    </Layout>
  );
};

const scrollSmoothly = (ref: React.MutableRefObject<HTMLDivElement|null>) => {
  const y_coord = ref?.current?.offsetTop ?? window.innerHeight;
  const padding = document.documentElement.querySelector("[class*='navigation-']")?.clientHeight ?? window.innerHeight / 6;

  window.scrollTo({
    top: y_coord - padding,
    behavior: "smooth"
  });
};

export default StartupsPage;

export const Panel = styled.div<{background: string}>`
  ${({ theme, background }) => css`
    background: ${background};

    .looking-for-investment-wrapper {
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
      }

      .center-copy{
        text-align: center;
        max-width: 65rem;
        margin: 0 auto;
      }

      .copy {
        z-index: 2;
        padding-top: 4.2rem;

        @media (min-width: 768px) {
          padding-top: 0;
          width: 100%;
          max-width: 45.2rem;
        }

        h2 {
          font-size: ${theme.font.size.subhead_3};

          @media (min-width: 960px) {
            font-size: 3rem;
          }
        }

        p {
          font-size: ${theme.font.size.body_2};
        }
      }

      .section-cta-container {
        padding-top: 0
        width: 100%;

        p {
          padding-top: 0;
          font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
        }
        
        .book {
          padding-top: 2.6rem;
          font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
          font-weight: ${fontWeightHelper({
    theme,
    variant: "bold"
  })};
        }
      }
      
      .image {
        max-width: 60rem;
        transition: all 0.2s ease-in-out;

        @media (min-width: 768px) {
          margin-right: 5%;
        }

        @media (min-width: 1024px) {
          margin-right: 6%;
        }

        @media (min-width: 1280px) {
          margin-right: 8%;
        }

        @media (min-width: 1380px) {
          margin-right: 10%;
        }
      }
    }
  `};
`;