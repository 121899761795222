import { default as React } from "react";

import Link from "src/components/link";
import VideoBackgroundImage from "src/assets/images/application-development/app-video-backround-fallback.jpg";
import { FullWidthColumnWrapper } from "src/components/confines-wrappers";
import HeroVideo from "src/components/hero-video";
import PageTop from "src/components/page-top";
import styled from "src/theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper,
  spacingHelper
} from "src/theme/theme-helper";

export const PageTopInvestment: React.FC = () => {
  return (
    <PageTopCustom id="hero">
      <PageTop
        style={{
          paddingTop: 0,
          paddingBottom: 0
        }}
        height="large"
      >
        <HeroVideo
          src="https://cdn.gravitywell.co.uk/videos/application-development/Landing-Page-Apps_04.m3u8"
          fallbackImage={VideoBackgroundImage}
        />

        <FullWidthColumnWrapper className="top-content">
          <p className="heading">
            Investment & Sweat Equity
          </p>

          <div className="section-cta-container">
            <p className="book">
              <span className="off-screen">
                Click here and
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="#get-in-touch"
              >
                Chat to us
              </Link>
            </p>
          </div>
        </FullWidthColumnWrapper>
      </PageTop>

    </PageTopCustom>
  );
};

const PageTopCustom = styled.div`
  .investment-intro{
    text-align: center;
    .description {
      max-width: 100%;
    }
  }
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 3;
        text-align: center;

        .heading {
          transition: 0.2s ease-in-out;
          display: block;
          margin: 0 auto;
          width: 26rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }
    }
  }

  .section-cta-container {
    margin-top: ${({ theme }) => spacingHelper({
    theme,
    variant: "xxl"
  })};
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 32rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 39rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 53rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 60rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 66rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 74rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 80rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_7"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 96rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_9"
    })};
          }
        }
      }
    }
  }
`;
